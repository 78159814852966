li{
    display: flex;
    gap: 3px;
    align-items: center;
    justify-content: left;
    margin:1em;
    border: 1px solid black;
    padding: 0.5em 2em;
    text-align: left;
}
button{
    border: none;
    padding:0.5em 1.5em;
    cursor: pointer;
    background-color: beige;
}
form{
    display: flex;
    gap:5px;
    align-items: center;
    margin: 1em;
}
.container{
   display: flex;
   flex-direction: column;
   justify-content: center;
   align-items: left;
   background-color: solid grey;
}